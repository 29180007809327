<template>
	<div>
  <div class="class_nav">
    <span>当前位置：</span> <span>作品管理</span> > <span>{{title}}</span>
  </div>
  <div v-if="course_work.length>0" class="works-center">
   <div class="works-top dis-flex">
     <div class="works-img">
       <img :src="init_data.cover_img" alt="">
     </div>
     <div class="works-title">
       <h3>{{init_data.title}}</h3>
       <p style="padding: 10px 0"><span style="color: #999999">学生：</span> <span>{{init_data.student_name}}</span></p>
       <p><span style="color: #999999">老师：</span> <span>{{init_data.teacher_name}}</span></p>
     </div>
   </div>
    <div class="works-list">
      <div class="production-by dis-flex">
        <div class="production-by-item cursor-pointer" @click="changetab(n,index)" :class="isactive===index?'production-by-item-ac':'production-by-item'"
              v-for="(n,index) in list" :key="index">
          {{n.name}}
        </div>
      </div>
      <div v-if="production"  v-for="(w,index) in course_work"  :key="index">
        <div class="production_img">
          <img :src="w.works_pic" alt="">
        </div>
      </div>
      <div v-if="curriculum" class="curriculum">
        <div v-html="content"></div>
      </div>
      <div v-if="job" v-html="course_job" class="job">
			<div class="job-item dis-flex space-between"
	            v-for="(j,index) in jonList" :key="index">
	           <h3>{{j.title}}</h3>
	         <div class="job-item-btn cursor-pointer" @click="jobdetail(j)">查看详情</div>
	       </div>

	    </div>
    </div>
  </div>
  <div v-else class="wushuju">
    <img src="../../assets/images/wushuju.png" alt="">
         <p class="wushuju-text">暂无数据</p>
  </div>
</div> 
</template>

<script>
	import api from '@/service/api/api'
	export default {
		name: "works",
		data() {
			return {
				title: '',
				isactive: 0,
				list: [
					{id:'0',name:'作品'},
					{
						id: '1',
						name: '课程'
					},
					// {id:'2',name:'作业'},
				],
				production: true,
				curriculum: false,
				job: false,
				init_data: [],
				// 富文本
				content: ``,
				course_job: ``,

				course_work: [],
			}
		},
		created() {
			this.title = this.$route.query.title
			console.log(this.$route.query.index)

			this.workdetail()
		},
		methods: {
			// 初始化
			workdetail() {
				let params = {
					classmember_id: this.$route.query.classmember_id,
					course_id: this.$route.query.course_id,
				}
				api.workdetail(params).then(res => {
					if (res.errcode === 0) {
						this.init_data = res.data.course
						this.content = res.data.course.detail
						this.course_work = res.data.course_work

						this.course_job = res.data.course_job[0].content
					} else {
						this.$message({
							message: res.errmsg,
							duration: 2000,
							type: "warning"
						});
					}
				})
			},
			// tab选项
			changetab(n, index) {
				// this.production = []
				this.isactive = index;
				console.log(index)
				if (index === 0) {
					this.production = true
					this.curriculum = false
					this.job = false

				} else if (index === 1) {
					this.production = false
					this.curriculum = true
					this.job = false
					console.log(this.curriculum)
				} else if (index === 2) {
					this.production = false
					this.curriculum = false
					this.job = true
				}
				// let student_name = this.list[index].student_name


			},

		}
	}
</script>

<style scoped>
	/* 课程 */
	.courseItems {
		height: 75px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 30px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 10%);
		margin: 15px 0;
		border-radius: 10px;

	}

	.courseItems p {
		color: #666;
		font-size: 20px;
	}

	.courseItems a {
		text-align: center;
		width: 100px;
		height: 32px;
		display: block;
		line-height: 32px;
		background: #ff8e42;
		border-radius: 4px;
		color: #fff;
	}



	.wushuju {
		width: 235px;
		height: 600px;
		margin: 100px auto;
	}

	.wushuju>img {
		width: 235px;
		height: 196px;
	}

	.class_nav {
		width: 1400px;
		margin: 0 auto;
		height: 40px;
		line-height: 40px;
		color: #FF8E42;
	}

	.works-center {
		width: 1400px;
		margin: 0 auto;
		min-height: 867px;
		background: #FFFFFF;
		padding: 20px;
		border-radius: 8px;
	}

	.works-top {
		width: 100%;
		height: 160px;
		margin-top: 20px;
		background: #f8f8f8;
		padding: 20px;
	}

	.works-img {
		width: 110px;
		height: 110px;
	}

	.works-img>img {
		width: 100%;
		height: 100%;
		border-radius: 20px 0 20px 0;
	}

	.works-title {
/* 		padding-top: 5px; */
		padding-left: 15px;
	}
	.works-title span {
		font-size: 16px;
		font-weight: bold;
		color: #666;
	}
	.works-title p {
		font-size: 16px;
		font-weight: bold;
		color: #222;
		padding: 5px 0;
	}

	.works-list {
		width: 100%;
		min-height: 500px;
		margin-top: 30px;
	}

	.production-by {
		width: 750px;
		height: 50px;
		line-height: 50px;
	}

	.production-by-item {
		min-width: 50px;
		height: 40px;
		line-height: 40px;
		font-size: 18px;
		font-weight: 400;
		text-align: center;
		margin: 0 20px;
		color: #666666;
	}

	.production-by-item-ac {
		min-width: 50px;
		height: 40px;
		line-height: 40px;
		font-size: 18px;
		text-align: center;
		color: #FF8E42;
		font-weight: 400;
		margin: 0 20px;
		border-bottom: 2px solid #FF8E42;
	}

	/*作品*/
	.production_img {
		margin: 20px 0 0 0;
	}

	.production_img>img {
		max-width: 100%;
	}

	.curriculum {
		width: 1000px;
		min-height: 394px;
		margin: 20px auto;
	}

	.job {
		width: 1000px;
		min-height: 394px;
		margin: 20px auto;
	}

	.job-item {
		width: 100%;
		height: 80px;
		line-height: 80px;
		background: #FFFFFF;
		box-shadow: 0 0 21px 3px rgba(4, 0, 0, 0.05);
		border-radius: 9px;
		margin-top: 20px;
	}

	.job-item>h3 {
		font-size: 20px;
		font-weight: 400;
		color: #999999;
		padding-left: 20px;
	}

	.job-item-btn {
		width: 90px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		margin: 20px 20px 0 0;
		background: #FF8E42;
		border-radius: 8px;
		font-size: 14px;
		font-weight: 400;
		color: #FFFFFF;
	}
</style>
